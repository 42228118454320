import Vue from 'vue'
import store from '../store/index'
import VueRouter from 'vue-router'
import Inicio from '../views/Inicio.vue'
import Colaboradores from '../views/Colaboradores.vue'
import Historico from '../views/Historico.vue'
import EstatusEvaluaciones from '../views/EstatusEvaluaciones.vue'
import CargaMasiva from '../views/CargaMasiva.vue'

import axios from 'axios'

Vue.use(VueRouter)

const routes = [
	{
		path: '/',
		name: 'Inicio',
		component: Inicio,
		meta: { layout: 'Login' }
		// component: () => import("../views/About.vue")
	},
	{
		path: '/Colaboradores',
		name: 'Colaboradores',
		component: Colaboradores,
		meta: { layout: 'Default' }
		// component: Colaboradores
	},
	{
		path: '/Historico',
		name: 'Historico',
		component: Historico,
		meta: { layout: 'Default' }
		// component: Colaboradores
	},
	{
		path: '/EstatusEvaluaciones',
		name: 'EstatusEvaluaciones',
		meta: { layout: 'Default' },
		component: EstatusEvaluaciones
	},
	{
		path: '/CargaMasiva',
		name: 'CargaMasiva',
		meta: { layout: 'Default' },
		component: CargaMasiva
	},
	{
		path: '/Evaluacion',
		name: 'Evaluacion',
		meta: { layout: 'Default' },
		component: () => import('@/views/Evaluacion')
	},
	{
		path: '/EvaluacionJefatura',
		name: 'EvaluacionJefatura',
		meta: { layout: 'Default' },
		component: () => import('@/views/EvaluacionJefatura')
	},
	{
		path: '/MisEvaluaciones',
		name: 'MisEvaluaciones',
		meta: { layout: 'Default' },
		component: () => import('@/views/MisEvaluaciones')
	},
	{
		path: '/Resumen',
		name: 'Resumen',
		meta: { layout: 'Default' },
		component: () => import('@/views/Resumen')
	},
	{
		path: '/Admin/Colaboradores',
		name: 'AdminColaboradores',
		meta: { layout: 'Default' },
		component: () => import('@/views/Admin/Colaboradores')
	},
	{
		path: '/Admin/Periodos',
		name: 'AdminPeriodos',
		meta: { layout: 'Default' },
		component: () => import('@/views/Admin/Periodos')
	},
	{
		path: '/Calibracion',
		name: 'Calibracion',
		meta: { layout: 'Default' },
		component: () => import('@/views/Calibracion')
		// component: Colaboradores
	}
]

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes
})

router.beforeEach((to, from, next) => {
	store.dispatch('fetchAccessToken')

	if (store.state.accessToken === null) {
		if (to.name !== 'Inicio') {
			store.dispatch('logout')
		}
	} else {
		axios.interceptors.response.use(function (response) {
			return response
		}, function (error) {
			if (error.response) {
				if (error.response.status === 401) {
					store.dispatch('logout')
					next({ name: 'Inicio' })
				}
			}
			return Promise.reject(error)
		})
		let permiteMenu = false
		if (to.name === 'Inicio' || to.name === 'Evaluacion' || to.name === 'Resumen') {
			permiteMenu = true
		}
		if (store.state.accessMenu !== null) {
			store.state.accessMenu.forEach((value, key) => {
				// opciones de submenu
				if (value.enlace === null && value.hijos.length) {
					value.hijos.forEach((value2, key) => {
						if (to.name === value2.enlace) {
							permiteMenu = true
						}
					})
				// opcion menu normal
				} else {
					if (to.name === value.enlace) {
						permiteMenu = true
					}
				}
			})
		}

		if (!permiteMenu) {
			next({ name: 'Inicio' })
		}
	}
	next()
})

export default router
