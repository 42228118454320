<template>
  <div id="colaboradores" class="container-fluid px-4 pt-4">
	<div class="row">
      <div :style="{ cursor: 'pointer' }" @click="getColaboradores(rowEstado.id)" v-for="(rowEstado, keyEstado) in estadosEvaluacion" :key="keyEstado" class="col-lg">
        <b-card :style="{ 'background-color': (estadoActivo == rowEstado.id  ? '#dfdfdf' : '') }" class="border-0 shadow mb-4">
          <b-media class="d-flex">
            <template #aside>
              <span class="media-icon" :style="'background-color:'+rowEstado.color">
                <span style="margin-top: 6px;color:white" class="material-icons">{{rowEstado.icono}}</span>
              </span>
            </template>
            <h5 class="mt-0 text-morado-01 font-400 mb-3">{{rowEstado.nombre}}</h5>
            <div class="row">
              <div v-for="(rowPais, keyPais) in paises" :key="keyPais"
                class="text-nowrap text-morado-01 font-600"
				v-bind:class="[
					paises.length == 3 ? 'col-lg-4' : 'col-lg-6',
					keyPais < paises.length-1 ? 'border-end' : ''
				]"
              >
				{{getCantidad(rowEstado.id, rowPais.id)}}&nbsp;
				<span class="text-morado-02 text-small font-300">{{rowPais.nombre_corto}}</span>
              </div>
            </div>
          </b-media>
        </b-card>
      </div>
      <div class="col-12">
        <b-card class="border-0 shadow mb-4">
          <div class="row">
            <div class="col-lg d-flex align-items-center">
				<h5 class="font-500 text-morado-01">Histórico evaluaciones</h5>
            </div>
            <div class="col-lg-auto">
				<b-button class="rounded btn-secondary bg-morado-03 border-0 text-morado-01 py-2" @click="recargarListado" v-b-tooltip.hover="'Recargar listado'">
					<i class="material-icons text-larger align-middle">refresh</i>
				</b-button>
			</div>
			<div class="col-lg-3">
              <div class="form-group mb-3">
                <multiselect
                  class="bg-gris-01 borde-gris-02 text-morado-01"
                  placeholder="Seleccione periodo"
                  label="descripcion"
                  track-by="id"
                  v-model="periodoFiltro"
                  :options="comboPeriodos"
                  :multiple="false"
                  :allow-empty="false"
				  @input="getColaboradores(estadoActivo)"
                  :max-height="340"
                  selected-label="done"
                  select-label=""
                  deselect-label=""
                  :searchable="true"
                >
                  <template v-slot:noOptions
                    >No se encontraron resultados.</template
                  >
                  <template v-slot:noResult
                    >No se encontraron resultados.</template
                  >
                </multiselect>
              </div>
            </div>
            <div class="col-lg-3">
              <div class="form-group mb-3">
                <multiselect
                  class="bg-gris-01 borde-gris-02 text-morado-01"
                  placeholder="Seleccione área"
                  label="area"
                  track-by="area_id"
                  v-model="area"
                  :options="comboAreas"
                  :multiple="false"
                  :allow-empty="false"
                  @input="getColaboradores(estadoActivo)"
                  :max-height="340"
                  selected-label="done"
                  select-label=""
                  deselect-label=""
                  :searchable="true"
                >
                  <template v-slot:noOptions
                    >No se encontraron resultados.</template
                  >
                  <template v-slot:noResult
                    >No se encontraron resultados.</template
                  >
                </multiselect>
              </div>
            </div>
            <div class="col-lg-3">
              <div class="form-group mb-3 has-icon-left">
                <input
                  type="text"
                  class="form-control bg-gris-01 borde-gris-02 text-morado-01 text-small"
                  v-model="filtros.buscador.value"
                  placeholder="Búsqueda..."
                />
                <span class="icon-left">
                  <span class="material-icons text-morado-01">search</span>
                </span>
              </div>
            </div>
            <hr class="mb-0" />
            <div class="col-12 px-0" v-if="colaboradores.length">
              <v-table
                class="table text-small"
                :data="colaboradores"
				:currentPage.sync="currentPage"
				:pageSize="pageSize"
				:filters="filtros"
				@totalPagesChanged="totalPages = $event"
              >
                <thead slot="head">
                  <th class="bg-azul-01 py-2 px-3">Nombre</th>
                  <th class="bg-azul-01 py-2 px-3">Área</th>
                  <th class="bg-azul-01 py-2 px-3">Cargo</th>
				  <th class="bg-azul-01 py-2 px-3">Estado</th>
                  <!--th class="bg-azul-01 py-2 px-3 text-center">Top Talent</th-->
                  <th class="bg-azul-01 py-2 px-3 text-center">Avance</th>
                  <th class="bg-azul-01 py-2 px-3 text-center"></th>
                </thead>
                <tbody slot="body" slot-scope="{ displayData }">
                  <tr v-for="row in displayData" :key="row.id">
                    <td class="text-morado-01 px-3 font-500">
                      <span
                        class="d-inline-block text-truncate align-middle"
                        style="max-width:300px;"
                        >{{ row.nombre_completo }}</span
                      >
                    </td>
                    <td class="px-3">{{ row.area }}</td>
                    <td class="px-3">{{ row.cargo }}</td>
					<td class="px-3"><span class="estado-evaluacion" :style="'background-color:' + row.estado_color">{{row.estado_nombre}}</span></td>
                    <!--td class="px-3 text-nowrap text-center"-->
                      <!--span
                        v-html="
                          estrellasToptalent(row.toptalent, 'text-amarillo-02')
                        "
                      ></span-->
                    <!--/td-->
                    <td class="px-3">
                      <b-progress
                        class="mx-auto"
                        height="2rem"
                        :max="100"
                        show-progress
                      >
                        <b-progress-bar :style="'background-color:'+row.estado_color" :value="getAvance(parseInt(row.estado_evaluacion), parseInt(row.evaluacion_jefe))">
                          <!-- 20% -->
                        </b-progress-bar>
                      </b-progress>
                    </td>
                    <td class="px-3 text-end">
					<!-- Solo habilita boton cuando se encuentre en estado 'Entregada' (finalizada la evaluación) -->
                      <b-button v-if="parseInt(row.estado_id) == 4 && (parseInt(row.jefatura_id) == decodeString(idUsuarioConectado))"
						:to="{name: 'Evaluacion', params: {page_historico: true, persona: {id: row.id, nombre: row.nombre_completo, cargo: row.cargo, area: row.area, pais: row.pais_nombre}, periodo: periodoEvaluacion}}"
                        variant="morado"
                        size="sm"
                        class="me-2"
                      >
                        <i class="material-icons text-normal align-middle"
                          >assignment</i
                        >
                      </b-button>
					  <!-- Botón resumen -->
                      <b-button
					  v-b-popover.hover.left.html="{ content: getAlertaDashboard(row) }"
					  @click="verDashboard(row)" variant="morado" size="sm">
                        <i class="material-icons text-normal align-middle"
                          >bar_chart</i
                        >
                      </b-button>
                    </td>
                  </tr>
                </tbody>
              </v-table>
			  <smart-pagination v-if="colaboradores.length > pageSize" :class="'text-center d-flex justify-content-center text-small py-3'"
					:currentPage.sync="currentPage"
					:totalPages="totalPages"
					:maxPageLinks="maxPageLinks"
				/>
			</div>
			<div class="col-12 px-0" v-else>
              <EmptyTable
                icono="folder_open"
                claseTabla="table table-empty"
              />
            </div>
          </div>
        </b-card>
      </div>
    </div>
	<loading
      :active.sync="isLoading"
      :can-cancel="false"
      :is-full-page="true"
    ></loading>
  </div>
</template>

<script>
import EmptyTable from '@/ui/EmptyTable.vue'
import { mapState, mapActions } from 'vuex'
export default {
	name: 'Colaboradores',
	data: () => {
		return {
			isLoading: false,
			comboAreas: [],
			area: null,
			colaboradores: [],
			totalEstrellas: 5,
			currentPage: 1,
			totalPages: 0,
			maxPageLinks: 3,
			pageSize: 10,
			filtros: {
				buscador: { value: '', keys: ['nombre_corto', 'cargo', 'area', 'estado_nombre'] }
			},
			estadosEvaluacion: [],
			indicadoresEstados: [],
			estadoActivo: null,
			idUsuarioConectado: 0,
			comboPeriodos: [],
			periodoFiltro: null
		}
	},
	methods: {
		...mapActions(['doPais', 'doPaises', 'doRoles', 'doMenu']),
		verDashboard (row) {
			if (this.getAlertaDashboard(row) !== '') {
				return false
			}
			this.$router.push({
				name: 'Resumen',
				params: {
					persona: {
						id: row.id,
						nombre: row.nombre_completo,
						cargo: row.cargo,
						area: row.area,
						pais: row.pais_nombre
					},
					periodo: this.periodoEvaluacion,
					estadoEvaluacion: {
						nombre: row.estado_nombre,
						color: row.estado_color
					},
					page_historico: true
				}
			})
		},
		getAlertaDashboard (row) {
			let msg = ''
			if (parseInt(row.estado_evaluacion) < 2) {
				msg = msg + '- Para visualizar, la evaluación no puede estar en estado \'Pendiente\''
			}
			/* if (parseInt(row.es_jefe)) {
				if (!parseInt(row.autoevaluacion)) {
					msg = (msg !== '') ? msg + '<br>' : ''
					msg = msg + '- El evaluado debe completar la \'Autoevaluación\''
				}
				if (!parseInt(row.evaluacion_equipo)) {
					msg = (msg !== '') ? msg + '<br>' : ''
					msg = msg + '- El evaluado debe tener \'Evaluación de su equipo\''
				}
			} */
			const msgFinal = (msg !== '') ? '<b>Dashboard</b><br>' : ''
			return msgFinal + msg
		},
		getMensajePeriodo () {
			let msg = ''
			if (this.periodoEvaluacion.estado === 'periodo_no_disponible') {
				msg = 'Periodo no disponible'
			} else if (this.periodoEvaluacion.estado === 'periodo_proximo') {
				msg = 'Periodo aún no comienza'
			} else if (this.periodoEvaluacion.estado === 'periodo_finalizado') {
				msg = 'Periodo finalizado'
			}
			return msg
		},
		recargarListado () {
			this.estadoActivo = null
			this.area = null
			this.getColaboradores()
		},
		getColaboradores (idEstado = null) {
			if (idEstado !== null) {
				this.estadoActivo = idEstado
			}
			let idArea = null
			if (this.area !== null) {
				idArea = this.area.area_id
			}
			let idPais = null
			if (this.pais !== null) {
				idPais = this.pais.id
			}
			let idPeriodo = null
			if (this.periodoFiltro !== null) {
				idPeriodo = this.periodoFiltro.id
			}
			this.isLoading = true
			this.colaboradores = []
			this.axios
				.post(this.apiurl + 'Encuesta/getHistorico', {
					id_pais: idPais,
					id_estado: idEstado,
					id_area: idArea,
					id_periodo: idPeriodo
				}, this.auth)
				.then(response => {
					this.comboAreas = response.data.areas
					this.periodoEvaluacion = response.data.periodo_evaluacion
					this.comboPeriodos = response.data.periodos
					if (this.periodoFiltro === null) {
						this.comboPeriodos.forEach((value, key) => {
							if (parseInt(value.estado) === 1) { // periodo disponible por defecto
								this.periodoFiltro = value
							}
						})
					}
					this.estadosEvaluacion = response.data.estados_evaluacion
					this.indicadoresEstados = response.data.indicadores_estados
					this.colaboradores = response.data.result
					this.idUsuarioConectado = response.data.id_usuario_conectado
					this.isLoading = false
				})
				.catch(error => {
					console.log(error)
					this.isLoading = false
				})
		},
		getCantidad (idEstado, idPais) {
			let result = 0
			this.indicadoresEstados.forEach((value, key) => {
				if (parseInt(value.estado_id) === parseInt(idEstado) && parseInt(value.pais_id) === parseInt(idPais)) {
					result = value.cantidad
				}
			})
			return result
		},
		getAvance (idEstado, jefeEvaluado) {
			const suma = (idEstado) ? (idEstado * 25) : 0
			// suma = suma + (parseInt(jefeEvaluado) ? 20 : 0)
			return suma
		},
		decodeString (string) {
			return parseInt(window.atob(string))
		}
	},
	watch: {
		pais: function (val) {
			this.getColaboradores()
		}
	},
	mounted () {
		this.getColaboradores()
		/*
		setTimeout(() => {
			this.colaboradores = [
				{
					id: 1,
					avatar: 'user-1.svg',
					nombre: 'Tulio Triviño',
					area: 'Área Comercial',
					cargo: 'Ejecutivo Comercial',
					toptalent: 4,
					desempeno: 40.2
				}
			]
		}, 500) */
	},
	components: {
		EmptyTable
	},
	computed: {
		...mapState(['pais', 'paises', 'auth', 'apiurl'])
	}
}
</script>
