import Vue from 'vue'
import Vuex from 'vuex'
import router from '../../src/router'

Vue.use(Vuex)

export default new Vuex.Store({
	state: {
		apiurl: 'https://apieval.latamtradecapital.com/',
		accessToken: null,
		nombreUsuario: '',
		auth: null,
		paises: null,
		accessRoles: null,
		accessMenu: null,
		pais: null,
		sidebarExpandido: false,
		mailLogin: null
	},
	mutations: {
		updateSidebarExpandido: (state, estado) => {
			state.sidebarExpandido = estado
		},
		updatePais: (state, pais) => {
			state.pais = pais
		},
		updatePaises: (state, paises) => {
			state.paises = paises
		},
		updateRoles: (state, accessRoles) => {
			state.accessRoles = accessRoles
		},
		updateMenu: (state, accessMenu) => {
			state.accessMenu = accessMenu
		},
		updateAccessToken: (state, accessToken) => {
			state.accessToken = accessToken
			state.auth = { headers: { Authorization: accessToken } }
		},
		updateNombreUsuario: (state, nombreUsuario) => {
			state.nombreUsuario = nombreUsuario
		},
		updateMailLogin: (state, mailLogin) => {
			state.mailLogin = mailLogin
		},
		logout: (state) => {
			state.accessToken = null
			state.auth = null
			state.pais = null
			state.paises = null
			state.accessRoles = null
			state.accessMenu = null
			state.nombreUsuario = ''
		}
	},
	actions: {
		doLogin ({ commit }, data) {
			// commit('loginStart')
			if (parseInt(data.token) === 0 || data.token === null) {
			  // localStorage.setItem('loginError', 0)
			  // commit('loginStop', 'error token')
				commit('updateAccessToken', null)
				commit('updateNombreUsuario', '')
				return false
			} else {
				localStorage.setItem('accessToken', data.token)
				commit('updateAccessToken', data.token)
				localStorage.setItem('nombreUsuario', data.nombre_usuario)
				commit('updateNombreUsuario', data.nombre_usuario)
			}
		},
		logout ({ commit }) {
			localStorage.removeItem('accessToken')
			localStorage.removeItem('pais')
			localStorage.removeItem('paises')
			localStorage.removeItem('accessRoles')
			localStorage.removeItem('accessMenu')
			localStorage.removeItem('nombreUsuario')
			localStorage.removeItem('mailLogin')
			commit('logout')
			router.push('/')
		},
		doSidebarExpandido ({ commit }, estado) {
			commit('updateSidebarExpandido', estado)
		},
		doPais ({ commit }, pais) {
			localStorage.setItem('pais', JSON.stringify(pais))
			commit('updatePais', pais)
		},
		doPaises ({ commit }, paises) {
			localStorage.setItem('paises', JSON.stringify(paises))
			commit('updatePaises', paises)
		},
		doRoles ({ commit }, accessRoles) {
			localStorage.setItem('accessRoles', JSON.stringify(accessRoles))
			commit('updateRoles', accessRoles)
		},
		doMenu ({ commit }, accessMenu) {
			localStorage.setItem('accessMenu', JSON.stringify(accessMenu))
			commit('updateMenu', accessMenu)
		},
		doMailLogin ({ commit }, mailLogin) {
			localStorage.setItem('mailLogin', mailLogin)
			commit('updateMailLogin', mailLogin)
		},
		fetchAccessToken ({ commit }) {
			commit('updatePaises', JSON.parse(localStorage.getItem('paises')))
			commit('updateRoles', JSON.parse(localStorage.getItem('accessRoles')))
			commit('updateMenu', JSON.parse(localStorage.getItem('accessMenu')))
			commit('updatePais', JSON.parse(localStorage.getItem('pais')))
			commit('updateAccessToken', localStorage.getItem('accessToken'))
			commit('updateNombreUsuario', localStorage.getItem('nombreUsuario'))
			commit('updateMailLogin', localStorage.getItem('mailLogin'))
		}
	},
	modules: {}
})
