<template>
	<div id="evaluaciones" class="container-fluid px-4 pt-4">
		<PeriodoEvaluacion class="mb-3" :periodoEvaluacion="periodoEvaluacion" />
		<div class="row justify-content-between">
			<div class="col-lg-auto">
				<h4 class="text-morado-01">Estatus de evaluaciones</h4>
				<p class="text-morado-01 text-small">Desde esta vista podrás revisar el estado de todas las evaluaciones enviadas</p>
			</div>
		</div>
		<div class="row">
			<div class="col-12">
				<b-card class="border-0 shadow mb-4">
					<div class="row justify-content-between">
						<div class="col-lg d-flex align-items-center">
							<h5 class="font-500 text-morado-01">Áreas disponibles</h5>
						</div>
						<div class="col-lg-3">
							<div class="form-group mb-3 has-icon-left">
								<input v-model="filtros.buscador.value" type="text" placeholder="Búsqueda..." class="form-control bg-gris-01 borde-gris-02 text-morado-01 text-small">
								<span class="icon-left">
									<span class="material-icons text-morado-01">search</span>
								</span>
							</div>
						</div>
						<!--div class="col-lg-auto d-flex align-items-center">
							<button class="btn btn-primary btn-sm mb-3 rounded">
								<i class="material-icons align-middle">arrow_circle_down</i>
								Descargar Nómina
							</button>
						</div-->
						<hr class="mb-0" />
						<div class="col-12 px-0" v-if="dataTable.length">
							<v-table
							class="table text-small"
							:data="dataTable"
							:currentPage.sync="currentPage"
							:pageSize="pageSize"
							:filters="filtros"
							@totalPagesChanged="totalPages = $event"
							>
								<thead slot="head">
									<th class="bg-verde-04 py-2 px-3 text-morado-01 py-3">Área</th>
									<template v-for="(estado, key2) in estadosEvaluacion">
										<th :key="key2" class="bg-verde-04 py-2 px-3 text-morado-01 py-3 text-center">{{estado.nombre}}</th>
									</template>
								</thead>
								<tbody slot="body" slot-scope="{ displayData }">
									<tr v-for="row in displayData" :key="row.id">
										<td class="px-3 text-uppercase text-morado-01">{{ row.area }}</td>
										<template v-for="(estado, key2) in estadosEvaluacion">
											<td :key="key2" class="px-3 text-center">
												<span :style="'color:white;background-color:'+estado.color" style="min-width: 50px;" class="d-inline-block px-3 py-1 rounded text-small hover text-center">
													<span v-if="row.data[estado.id]" class="text-smaller">
														{{ row.data[estado.id].cantidad }}
														<i v-if="estado.id == 4" class="material-icons align-middle text-normal">check_circle</i>
													</span>
													<span class="text-smaller" v-else>0</span>
												</span>
												<span @click="verJefaturas(row.area, row.area_id, estado.nombre, estado.id)" v-if="row.data[estado.id] && estado.id != 4 && estado.id != 2" class="d-inline-block px-2 py-1 rounded text-small text-morado-01 hover">
													<i class="material-icons align-middle">mail</i>
												</span>
											</td>
										</template>
									</tr>
								</tbody>
							</v-table>
							<smart-pagination v-if="dataTable.length > pageSize" :class="'text-center d-flex justify-content-center text-small py-3'"
								:currentPage.sync="currentPage"
								:totalPages="totalPages"
								:maxPageLinks="maxPageLinks"
							/>
						</div>
						<div class="col-12 px-0" v-else>
							<EmptyTable
							icono="folder_open"
							claseTabla="table table-empty"
							/>
						</div>
					</div>
				</b-card>
			</div>
			<!-- Modal jefes -->
			<b-modal v-model="modalJefaturas" size="lg" centered no-fade no-close-on-esc no-close-on-backdrop body-class="p-0" id="modal-jefaturas" v-if="dataModal != null" scrollable :title="dataModal.area + ' (' + dataModal.estado + ')'">
				<template #default>
					<div class="row mx-0 mb-4">
						<v-table
						class="table text-small"
						:data="dataTableModal"
						>
							<thead slot="head">
								<th style="width: 20%;" class="bg-verde-04 py-2 px-3 text-morado-01 py-3">Jefe</th>
								<th style="width: 64%;" class="bg-verde-04 py-2 px-3 text-morado-01 py-3 text-center">Pendientes</th>
								<th style="width: 16%;" class="bg-verde-04 py-2 px-3 text-morado-01 py-3"></th>
							</thead>
							<tbody slot="body" slot-scope="{ displayData }">
								<tr v-for="row in displayData" :key="row.id">
									<td style="height: auto;" class="px-3 text-uppercase text-morado-01 text-nowrap">{{ row.jefatura_nombre }}</td>
									<td style="height: auto;" class="px-3 text-uppercase text-morado-01 text-center">{{ row.cantidad }}</td>
									<td>
										<button @click="enviarNotificacion(false, row.jefatura_id, row.jefatura_nombre)" class="btn btn-morado btn-sm rounded text-nowrap">
											Enviar notificación individual
										</button>
									</td>
								</tr>
							</tbody>
						</v-table>
					</div>
				</template>
				<template #modal-footer>
					<div class="w-100 text-end">
						<button @click="enviarNotificacion(true)" class="btn bg-morado-01 text-blanco-01 mb-3 rounded">
							Enviar notificación masiva
						</button>
					</div>
				</template>
			</b-modal>
			<loading
			:active.sync="isLoading"
			:can-cancel="true"
			:is-full-page="true"
		></loading>
	  </div>
	</div>
</template>

<script>
import EmptyTable from '@/ui/EmptyTable.vue'
import PeriodoEvaluacion from '@/components/PeriodoEvaluacion.vue'
import { mapState } from 'vuex'
export default {
	  name: 'EstatusEvaluaciones',
	  data: () => {
		  return {
			  isLoading: false,
			  totalEstrellas: 5,
			  currentPage: 1,
			  totalPages: 0,
			  maxPageLinks: 3,
			  pageSize: 10,
			  filtros: {
				  buscador: { value: '', keys: ['area'] }
			  },
			  dataTable: [],
			  estadosEvaluacion: [],
			  dataModal: null,
			  dataTableModal: [],
			  periodoEvaluacion: null,
			  modalJefaturas: false
		  }
	  },
	  methods: {
		  getData () {
			  this.isLoading = true
			  this.axios
				  .post(this.apiurl + 'EstatusEvaluaciones/getListado', {}, this.auth)
				  .then(response => {
					  this.dataTable = response.data.result
					  this.estadosEvaluacion = response.data.estados
					  this.periodoEvaluacion = response.data.periodo
					  this.isLoading = false
				  })
				  .catch(error => {
					  console.log(error)
					  this.isLoading = false
				  })
		  },
		  verJefaturas (area, idArea, estado, idEstado) {
			  this.dataModal = {
				  area_id: idArea,
				  estado_id: idEstado,
				  area,
				  estado
			  }
			  this.isLoading = true
			  this.axios
				  .post(this.apiurl + 'EstatusEvaluaciones/getJefaturasArea', {
					  id_area: idArea,
					  id_estado: idEstado
				  }, this.auth)
				  .then(response => {
					  this.dataTableModal = response.data.result
					  this.isLoading = false
					  this.showModal('modal-jefaturas', true)
				  })
				  .catch(error => {
					  console.log(error)
					  this.isLoading = false
				  })
		  },
		  enviarNotificacion (masiva, idJefatura = null, nombreJefatura = null) {
			  const dataPost = {
				  id_area: this.dataModal.area_id,
				  id_estado: this.dataModal.estado_id,
				  id_jefatura: idJefatura
				  }
			  this.isLoading = true
			  this.axios
				  .post(this.apiurl + 'EstatusEvaluaciones/enviarNotificacion', dataPost, this.auth)
				  .then(response => {
					 let txt = 'Notificación masiva enviada exitosamente'
					  if (!masiva) {
						  txt = 'Notificación enviada exitosamente a "' + nombreJefatura + '"'
					  }
					  this.$swal({
						title: txt,
						icon: 'success',
						confirmButtonText: 'Aceptar'
					  })
					  this.isLoading = false
				  })
				  .catch(error => {
					  console.log(error)
					  this.isLoading = false
				  })
		  },
		  showModal (id, mostrar) {
			console.log(id)
			this.modalJefaturas = mostrar
			if (mostrar) {
				// window.$('#' + id).fadeIn()
			} else {
				// window.$('#' + id).fadeOut()
			}
		  }
	  },
	  mounted () {
		  this.getData()
	  },
	  components: {
		  EmptyTable,
		  PeriodoEvaluacion
	  },
	  computed: {
		  ...mapState(['pais', 'auth', 'apiurl'])
	  }
}
</script>
