<template>
	<b-alert show :dismissible="cerrarAlerta" :variant="alertClass" v-if="periodoEvaluacion != null" class="mb-0 rounded border-0 font-300 text-small">
		<div class="row">
			<div class="col-lg-auto">
				<span class="material-icons">{{alertIcon}}</span>
			</div>
			<div class="col-lg px-0 d-flex align-items-center">
				<p class="m-0">{{texto}}</p>
			</div>
		</div>
	</b-alert>
</template>

<script>
export default {
	name: 'PeriodoEvaluacion',
	methods: {
	},
	watch: {
		periodoEvaluacion (valor) {
			if (valor.estado) {
				switch (valor.estado) {
				case 'periodo_proximo':
					this.texto = 'El periodo de evaluación comienza en ' + valor.dias + '.'
					this.alertClass = 'info'
					this.alertIcon = 'calendar_today'
					break
				case 'periodo_finalizado':
					this.texto = 'El periodo de evaluación finalizó hace ' + valor.dias + '.'
					this.alertClass = 'warning'
					this.alertIcon = 'warning'
					break
				case 'periodo_en_curso':
					this.texto = 'El periodo de evaluación finaliza en ' + valor.dias + '.'
					this.alertClass = 'primary'
					this.alertIcon = 'schedule'
					break
				case 'periodo_no_disponible':
					this.texto = 'No existe un periodo de evaluación.'
					this.alertClass = 'danger'
					this.alertIcon = 'do_not_disturb_on'
					break
				}
			}
		}
	},
	mounted () {
	},
	data: () => {
		return {
			texto: '',
			alertClass: 'info',
			alertIcon: 'calendar_today'
		}
	},
	props: {
		periodoEvaluacion: {
			type: Object,
			default: null
		},
		cerrarAlerta: {
			type: Boolean,
			default: true
		}
	}
}
</script>
