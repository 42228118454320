<template>
	<div id="evaluaciones" class="container-fluid px-4 pt-4 vld-parent">
		<div class="row">
			<div class="col-lg-3">
              <div class="form-group mb-3">
				<h4 class="text-morado-01">Selecciones tipo de carga</h4>
                <multiselect
                  class="bg-gris-01 borde-gris-02 text-morado-01"
                  placeholder="Seleccione"
                  label="name"
                  track-by="code"
                  v-model="tipoCarga"
                  :options="comboTipoCarga"
                  :multiple="false"
                  :allow-empty="false"
                  :max-height="340"
                  selected-label="done"
                  select-label=""
                  deselect-label=""
                  :searchable="true"
				  @input="obtenerUltimaCarga"
                >
                  <template v-slot:noOptions
                    >No se encontraron resultados.</template
                  >
                  <template v-slot:noResult
                    >No se encontraron resultados.</template
                  >
                </multiselect>
              </div>
            </div>
		</div>
		<template v-if="tipoCarga != null">
		<div class="row justify-content-between">
			<div class="col-lg-auto">
				<h4 class="text-morado-01">Cargar masiva '{{tipoCarga.name}}'</h4>
				<p class="text-morado-01 text-small">Recuerda que debes subir un archivo en formato XLXS.</p>
			</div>
		</div>
		<div class="row">
			<div class="col-lg">
				<div class="card border-0 bg-morado-03 px-3 py-4">
					<div class="row">
						<div class="col-lg-4">
							<div>Archivo seleccionado: {{ nombreArchivo ? nombreArchivo : '' }}</div>
							<b-form-file
							accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
							v-model="archivo"
							:state="Boolean(archivo)"
							placeholder="Selecciona un archivo o arrastralo hasta acá..."
							drop-placeholder="Arrastrar hacia acá..."
							></b-form-file>
						</div>
						<div class="col-lg-4">
							<button class="btn btn-primary my-3 rounded"
								:disabled="(!dataExcel.length)"
								title="Cargar información"
								v-on:click="cargarInformacion()"
							>
								<span class="material-icons align-middle text-normal">file_upload</span>
								<span>Cargar información</span>
							</button>
						</div>
						<div class="col-lg-4">
							<label>&nbsp;</label><br>
							<!-- Botón exportar -->
							<button title="Descargar Formato" class="btn btn-download mb-3" v-on:click="exportarFormato()">
								<span class="fa fa-file-excel"></span>
								<span>Descargar formato</span>
							</button>
						</div>
					</div>
					<div class="row" v-if="mensaje_ultima_carga">
						<div class="col-lg-auto">
							<p class="bg-warning2 text-warning2 py-3 px-4 rounded">{{mensaje_ultima_carga}}</p>
						</div>
					</div>
					<div class="row" v-if="mensaje_resultado">
						<div class="col-lg-auto">
							<p :class="('py-3 px-4 rounded '+class_resultado)" class="">{{mensaje_resultado}}</p>
						</div>
					</div>
				</div>
			</div>
		</div>
		</template>
		<loading
			:active.sync="isLoading"
			:can-cancel="false"
			:is-full-page="false"
		></loading>
	</div>
</template>

<script>

import { mapState } from 'vuex'
import * as XLSX from 'xlsx'

export default {
	name: 'CargaMasiva',
	data () {
		return {
			isLoading: false,
			archivo: null,
			dataExcel: [],
			nombreArchivo: '',
			mensaje_resultado: '',
			class_resultado: 'bg-azul-02 text-azul-03',
			mensaje_ultima_carga: '',
			comboTipoCarga: [
				{ name: 'ClimaLaboral', code: 'clima' },
				{ name: 'Liderazgo', code: 'liderazgo' },
				{ name: 'EncuestaClienteInterno', code: 'encuesta_cli_int' }
				// { name: 'KPIs', code: 'kpis' }
			],
			tipoCarga: null
		}
	},
	watch: {
		archivo: function (val) {
			this.excelToJson()
		},
		tipoCarga: function (val) {
			this.mensaje_resultado = ''
			this.class_resultado = 'bg-azul-02 text-azul-03'
			this.mensaje_ultima_carga = ''
			this.archivo = null
			this.dataExcel = []
			this.nombreArchivo = ''
		}
	},
	computed: {
		...mapState(['pais', 'paises', 'auth', 'apiurl'])
	},
	mounted () {
		// this.obtenerUltimaCarga()
	},
	methods: {
		exportarFormato: function () {
			const campos = [{
				rut: '',
				porcentaje: ''
			}]
			const animalWS = XLSX.utils.json_to_sheet(campos)
			const wb = XLSX.utils.book_new()
			let txt = this.tipoCarga.name
			if (this.tipoCarga.name === 'EncuestaClienteInterno') {
				txt = 'EncCliInt'
			}
			const name = 'CargaMasiva' + txt
			XLSX.utils.book_append_sheet(wb, animalWS, name)
			const date = new Date()
			const fecha = date.getFullYear() + '' + (date.getMonth() + 1) + '' + date.getDate()
			XLSX.writeFile(wb, name + '_' + fecha + '.xlsx')
		},
		cargarInformacion: function () {
			if (!this.dataExcel.length) {
				return false
			} else {
				this.isLoading = true
				this.axios.post(this.apiurl + 'CargaMasiva/cargarMasiva', { data: this.dataExcel, nombreArchivo: this.nombreArchivo, tipoCarga: this.tipoCarga.code },
					this.auth
				)
					.then((response) => {
						this.isLoading = false
						if (response.data.status === 200) {
							this.mensaje_resultado = response.data.mensaje
							this.class_resultado = 'bg-azul-02 text-azul-03'
							this.obtenerUltimaCarga()
						} else {
							this.mensaje_resultado = response.data.mensaje
							this.class_resultado = 'bg-error text-error'
						}
					})
					.catch((error) => {
						this.isLoading = false
						if (error.response) {
							this.mensaje_resultado = error.response.data.mensaje
							this.class_resultado = 'bg-error text-error'
						}
					})
			}
		},
		excelToJson: function () {
			this.dataExcel = []
			if (this.archivo === null) {
				return false
			}

			// Valido si archivo posee una extension válida
			if (this.archivo.type !== 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
				this.archivo = null
			} else {
				this.isLoading = true
				this.nombreArchivo = this.archivo.name
				const reader = new FileReader()
				reader.onload = (e) => {
					const data = new Uint8Array(e.target.result)
					const workbook = XLSX.read(data, {
						type: 'array',
						cellDates: true,
						cellNF: false,
						cellText: false
					})
					const sheetName = workbook.SheetNames[0]
					const worksheet = workbook.Sheets[sheetName]
					// Una vez procesada la información, contengo la informacion en variable, para luego validar en back
					setTimeout(() => {
						this.dataExcel = XLSX.utils.sheet_to_json(worksheet, {
							raw: false,
							dateNF: 'YYYY-MM-DD'
						})

						if (this.dataExcel.length < 1) {
							this.mensaje_resultado = 'El formato no tiene registros'
							this.class_resultado = 'bg-error text-error'
						} else {
							this.mensaje_resultado = ''
							this.class_resultado = 'bg-azul-02 text-azul-03'
						}
					}, 200)
				}
				reader.readAsArrayBuffer(this.archivo)
				setTimeout(() => {
					this.archivo = null
				}, 200)
				this.isLoading = false
			}
		},
		obtenerUltimaCarga: function () {
			this.isLoading = true
			this.axios.post(this.apiurl + 'CargaMasiva/obtenerUltimaCarga', { tipoCarga: this.tipoCarga.code },
				this.auth
			)
				.then((response) => {
					this.isLoading = false
					if (response.data.ultima_carga) {
						this.mensaje_ultima_carga = response.data.mensaje
						this.mensaje_resultado = response.data.result
					} else {
						this.mensaje_ultima_carga = response.data.mensaje
					}
				})
				.catch((error) => {
					this.isLoading = false
					if (error.response.data.mensaje) {
						this.mensaje_ultima_carga = error.response.data.mensaje
					} else {
						this.mensaje_ultima_carga = ''
					}
				})
		}
	}
}
</script>
