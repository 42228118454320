<template>
  <b-navbar v-if="accessToken" class="topbar" toggleable="lg" type="dark">
    <b-navbar-toggle target="topbar-nav-collapse">
      <span class="material-icons">menu</span>
    </b-navbar-toggle>
    <b-collapse id="topbar-nav-collapse" class="justify-content-between" is-nav>
      <b-navbar-nav>
        <b-nav-item
          class="d-flex align-items-center link-px-2"
          @click.prevent="alternaSidebar()"
        >
          <span class="material-icons">{{ chevronIcon }}</span>
        </b-nav-item>
        <b-nav-item class="link-px-0">
          <multiselect v-if="paises != null"
            class="selector-pais"
            placeholder="Seleccione país"
            label="nombre"
            track-by="id"
            v-model="paisSeleccionado"
            :options="paises"
            :loading="cargandoPaises"
            @input="seleccionarPais"
            :multiple="false"
            :allow-empty="false"
            :max-height="340"
            selected-label="done"
            select-label="done"
            deselect-label=""
            :searchable="true"
            :disabled="selectorPaisDisabled"
          >
            <template v-slot:noOptions>No se encontraron resultados.</template>
            <template v-slot:noResult>No se encontraron resultados.</template>
            <template slot="option" slot-scope="props">
              <img
                class="icono-bandera mr-3"
                :src="require('@/assets/img/banderas/' + props.option.svg)"
              />
              <span>{{ props.option.nombre }}</span>
            </template>
            <template slot="singleLabel" slot-scope="props">
              <img
                class="icono-bandera mr-3"
                :src="require('@/assets/img/banderas/' + props.option.svg)"
              />
              <span>{{ props.option.nombre }}</span>
            </template>
          </multiselect>
        </b-nav-item>
      </b-navbar-nav>
      <b-navbar-nav class="user-dropdown text-small">
        <b-nav-item-dropdown right>
          <template #button-content>
            <span class="material-icons pr-3">account_circle</span>
            <b style="padding-right: 10px;">{{nombreUsuario}}</b>
          </template>
          <!--b-dropdown-item class="text-small" href="#">Mis datos</b-dropdown-item-->
          <b-dropdown-item class="text-small" @click="actionLogout()" href="#">Cerrar sesión</b-dropdown-item>
        </b-nav-item-dropdown>
      </b-navbar-nav>
    </b-collapse>
    <div class="borde-inferior"></div>
  </b-navbar>
</template>

<script>
import { mapState, mapActions } from 'vuex'
export default {
	name: 'Topbar',
	data: () => {
		return {
			sidebar: false,
			cargandoPaises: false,
			selectorPaisDisabled: false,
			isLoading: false,
			paisSeleccionado: null
		}
	},
	watch: {
		pais: function (val) {
			this.paisSeleccionado = this.pais
		}
	},
	mounted () {
		if (this.pais !== null) {
			this.paisSeleccionado = this.pais
		}
	},
	methods: {
		...mapActions(['doSidebarExpandido', 'doPais', 'logout']),
		alternaSidebar () {
			this.doSidebarExpandido(!this.sidebarExpandido)
		},
		seleccionarPais () {
			this.doPais(this.paisSeleccionado)
		},
		actionLogout () {
			this.logout()
			/* this.isLoading = true
			this.axios
				.post(this.apiurl + 'Login/logout', {}, this.auth)
				.then(response => {
					this.logout()
					this.isLoading = false
				})
				.catch(error => {
					console.log(error)
					this.isLoading = false
				}) */
		}
	},
	computed: {
		...mapState(['sidebarExpandido', 'accessToken', 'pais', 'auth', 'apiurl', 'paises', 'nombreUsuario']),
		chevronIcon () {
			return this.sidebarExpandido ? 'chevron_left' : 'chevron_right'
		}
	}
}
</script>
