<template>
	<div id="login">
		<b-modal id="modal-login" content-class="p-4 px-5 border-0 shadow rounded" v-model="modalLogin" centered no-fade no-close-on-backdrop no-close-on-esc hide-backdrop hide-footer hide-header>
			<p class="mb-5 text-center">
				<img class="logo"
					:src="require('@/assets/img/ltc-logo-login.png')"
				/>
			</p>
			<p class="mb-2 text-center text-azul-03">Acceso usuario</p>
			<form @submit.prevent="validaAcceso($event)" :action="apiurl+'/Login/ValidarLogin2'" method="post">
				<div class="form-group mb-4">
					<input id="usuario" class="form-control ps-5 rounded" type="text" placeholder="Ingrese usuario" v-model="usuario" name="usuario" />
					<div class="icon-left">
						<i class="material-icons">person</i>
					</div>
				</div>
				<div class="text-center">
					<button type="submit" class="btn bg-morado-01 text-blanco-01 rounded w-100 btn-lg mb-4">Iniciar sesión</button>
				</div>
			</form>
			<loading
				:active.sync="isLoading"
				:can-cancel="false"
				:is-full-page="true"
			></loading>
		</b-modal>
	</div>
</template>

<script>

import { mapState, mapActions } from 'vuex'

export default {
	name: 'Inicio',
	data: () => {
		return {
			isLoading: false,
			usuario: '',
			modalLogin: true
		}
	},
	components: {},
	computed: {
		...mapState(['apiurl', 'accessToken', 'auth', 'accessRoles', 'accessMenu', 'paises', 'mailLogin'])
	},
	mounted () {
		// this.getDataLogin()
		if (this.accessToken !== null && this.accessMenu !== null) {
			const enlace = this.defaultRedirect(this.accessMenu)
			this.$router.push(enlace)
		} else {
			if (this.$route.query.token) {
				if (this.$route.query.mail !== null && this.$route.query.mail !== this.mailLogin) {
					this.$swal({
						text: 'Para acceder con el usuario \'' + this.mailLogin + '\' debes cerrar la cesión activa de Microsoft que tienes asociada a otra cuenta.',
						icon: 'warning',
						confirmButtonText: 'Volver'
					})
					this.logout()
				} else {
					this.afterLogin()
				}
			} else {
				this.logout()
			}
		}
	},
	methods: {
		...mapActions(['doLogin', 'doPaises', 'doPais', 'doRoles', 'doMenu', 'logout', 'doMailLogin']),
		validaAcceso (e) {
			if (this.usuario === '') {
				window.$('#usuario').focus()
				return false
			}
			this.isLoading = true
			this.axios
				.post(this.apiurl + 'Login/validaAcceso', { usuario: this.usuario }, this.auth)
				.then(response => {
					// this.isLoading = false
					if (response.data.result) {
						this.doMailLogin(this.usuario)
						e.target.submit()
					} else {
						this.isLoading = false
						this.$swal({
							title: 'Usuario no es valido',
							icon: 'error',
							confirmButtonText: 'Volver'
						})
					}
				})
				.catch(error => {
					console.log(error)
					this.isLoading = false
				})
		},
		afterLogin () {
			const data = { token: this.$route.query.token, nombre_usuario: this.$route.query.nombre }
			this.doLogin(data)
			if (this.accessMenu === null || this.accessRoles === null || this.paises === null) {
				this.getDataLogin()
			}
		},
		getDataLogin () {
			this.isLoading = true
			this.axios
				.post(this.apiurl + 'Persona/getDataLogin', {}, this.auth)
				.then(response => {
					const paises = response.data.paises
					const roles = response.data.roles
					const menu = response.data.menu
					if (paises) {
						this.doPaises(paises)
						this.doPais(paises[0])
					}
					if (roles) {
						this.doRoles(roles)
					}
					if (menu) {
						this.doMenu(menu)
					}
					this.isLoading = false
					const enlace = this.defaultRedirect(this.accessMenu)
					this.$router.push(enlace)
				})
				.catch(error => {
					console.log(error)
					this.isLoading = false
				})
		},
		defaultRedirect () {
			let redirect = ''
			this.accessMenu.forEach((value, key) => {
				if (parseInt(value.default_redirect)) {
					redirect = value.enlace
				}
			})
			return redirect
		}
	}
}
</script>
