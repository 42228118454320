<template>
  <div v-if="accessToken" class="sidebar">
    <div class="logo">
      <router-link :to="{ name: 'Inicio' }">
        <img
          :src="require('@/assets/img/' + logo)"
          :class="{
            'logo-expandido': this.sidebarExpandido,
            'logo-comprimido': !this.sidebarExpandido
          }"
        />
      </router-link>
      <div class="borde-inferior"></div>
    </div>
    <div class="list-wrapper">
      <ul class="list-group">
        <div class="list-group-module">
			<template v-for="(menu, keyMenu) in accessMenu">
				<router-link
					v-if="!menu.hijos.length"
					:to="{ name: menu.enlace }"
					class="list-group-item"
					:title="menu.nombre"
					:key="'a-'+keyMenu"
				>
					<span class="material-icons">{{menu.icono}}</span>
					<span v-if="sidebarExpandido">{{menu.nombre}}</span>
				</router-link>
				<div v-else class="dropdown-group" ref="dropdown" @mouseover="onOver" @mouseleave="onLeave" :key="'b-'+keyMenu">
					<b-button
						v-b-toggle="'collapse-01'"
						class="list-group-item dropdown-toggle w-100 text-start"
						:class="{'dropdown-toggle-no-caret': !sidebarExpandido }"
						:title="'LINK'"
						:key="'a-20'"
					>
						<span class="material-icons">{{menu.icono}}</span>
						<span v-if="sidebarExpandido">{{menu.nombre}}</span>
					</b-button>
					<b-collapse id="collapse-01" class="list-group-module">
						<router-link
							v-for="(hijo, keyHijo) in menu.hijos"
							:to="{ name: hijo.enlace }"
							class="list-group-item"
							:title="hijo.nombre"
							:key="'h'+keyHijo"
						>
							<span>{{hijo.nombre}}</span>
						</router-link>
					</b-collapse>
				</div>
			</template>
        </div>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
export default {
	name: 'Sidebar',
	methods: {
		...mapActions(['doSidebarExpandido']),
		comprimirSidebar: () => {
			this.doSidebarExpandido(false)
		},
		onOver () {
			// this.$refs.dropdown.visible = true
			for (let index = 0; index < this.$refs.dropdown.length; index++) {
				console.log('onOver', this.$refs.dropdown[index])
				this.$refs.dropdown[index].visible = true
			}
		},
		onLeave () {
			for (let index = 0; index < this.$refs.dropdown.length; index++) {
				console.log('onLeave', this.$refs.dropdown[index])
				this.$refs.dropdown[index].visible = false
			}
			// this.$refs.dropdown.visible = false
		}
	},
	computed: {
		...mapState(['sidebarExpandido', 'accessToken', 'accessMenu']),
		logo () {
			return this.sidebarExpandido ? 'ltc-chile.svg' : 'ltc-chile-mini.svg'
		}
	},
	mounted () {
	}
}
</script>
