<template>
	<div
	  id="app"
	  :class="{
		[this.sidebarClass]: true,
		[this.paisClass]: true
	  }"
	>
		<component :is="layout">
			<router-view />
		</component>
	</div>
</template>

<script>
import { mapState } from 'vuex'
import LoginLayout from './layout/Login.vue'

export default {
	components: {
		LoginLayout
	},
	computed: {
		...mapState(['sidebarExpandido', 'pais']),
		layout () {
			return (this.$route.meta.layout || this.default_layout) + 'Layout'
		},
		sidebarClass () {
			return this.sidebarExpandido ? 'sidebar-expandido' : 'sidebar-comprimido'
		},
		paisClass () {
			return this.pais ? 'pais-' + this.pais.id : 'sin-pais'
		}
	},
	data () {
		return {
			default_layout: 'Default'
		}
	}
}
</script>
